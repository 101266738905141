<template>
  <div>
    <v-container>
      <v-row>
        <v-container>
          <h2>
            เอกสารรับสินค้า
          </h2>
        </v-container>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="dataTable"
            :items-per-page="10"
            class="elevation-1"
            :search="searchTxt"
            dense
          >
            <template v-slot:top>
              <v-row class="mx-2" no-gutters>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="searchTxt"
                    append-icon="mdi-magnify"
                    label="ค้นหา"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-btn color="info" dark class="ma-3" @click="showClick">
                    {{ showTxt }}
                  </v-btn>
                  <v-btn color="primary" dark class="my-3" @click="newDoc">
                    <v-icon left dense>mdi-plus</v-icon>สร้างใหม่
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.RRDate="{ item }">
              <!-- <span>{{ new Date(item.RRDate).toLocaleString() }}</span> -->
              <span>{{ item.RRDate | moment("DD/MM/YYYY") }}</span>
            </template>
            <template v-slot:item.Actions="{ item }">
              <v-icon class="mr-3 primary--text" @click="editDoc(item)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:item.Status="{ item }">
              <v-icon class="info--text" v-if="item.Status == 1">
                mdi-check-bold
              </v-icon>
              <v-icon class="secondary--text" v-if="item.Status == 0">
                mdi-close-thick
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import { newReceiveRecord } from "@/store/modules/transactions/state";
import router from "@/router";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dataTableAll: [],
      showTxt: "แสดงทั้งหมด",
      searchTxt: "",
      headers: [
        {
          text: "เอกสาร",
          value: "RRId",
          divider: true,
          width: 130,
        },
        {
          text: "วันที่",
          value: "RRDate",
          divider: true,
        },
        {
          text: "ผู้จำหน่าย",
          value: "SupplierId",
          divider: true,
        },
        {
          text: "ชื่อผู้จำหน่าย",
          value: "SupplierName",
          divider: true,
        },
        {
          text: "ใบแจ้งหนี้",
          value: "InvoiceNo",
          divider: true,
        },
        {
          text: "วันที่ใบแจ้งหนี้",
          value: "InvoiceDateStr",
          divider: true,
        },
        {
          text: "หมายเหตุ",
          value: "Remark",
          divider: true,
        },
        {
          text: "สถานะ",
          value: "Status",
          divider: true,
          width: 130,
          align: "center",
        },
        {
          text: "",
          value: "Actions",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    dataTable: function() {
      if (this.showTxt == "แสดงทั้งหมด") {
        return this.dataTableAll.filter((list) => list.Status == 1);
      } else {
        return this.dataTableAll;
      }
    },
  },
  methods: {
    ...mapActions("transactions", ["actReceiveRecordGets"]),
    showClick() {
      if (this.showTxt == "แสดงทั้งหมด") {
        this.showTxt = "เฉพาะใช้งาน";
      } else {
        this.showTxt = "แสดงทั้งหมด";
      }
    },
    loadData() {
      this.actReceiveRecordGets().then(({ status, data }) => {
        if (status == 200 && data.success) {
          this.dataTableAll = data.val;
        } else {
          this.Error(data.message);
        }
      });
    },
    editDoc(item) {
      router.push({ name: "EditReceiveRecord", params: { item } });
    },
    newDoc(){
      router.push({ name: "EditReceiveRecord" });
    }
    // addLine() {
    //   this.itemDialogEdit = Object.assign({}, newReceiveRecord());
    //   this.itemIndexDialogEdit = -1;
    //   this.showDialogEdit = true;
    // },
    // editLine(item) {
    //   this.itemDialogEdit = item;
    //   this.itemIndexDialogEdit = this.dataTable.indexOf(item);
    //   this.showDialogEdit = true;
    // },
    // closeDialogEdit(value) {
    //   this.showDialogEdit = value;
    //   this.loadData();
    // },
  },
};
</script>

<style>
.container{
  max-width: 1500px;
}
</style>
